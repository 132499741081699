import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import MDXComponents from 'src/components/mdx-components';
import { Section } from 'src/components/molecules/Section';
import { ArticleTeaser } from 'src/components/organisms/ArticleTeaser';
import { Link } from 'src/containers/Link';
import { FluidImg } from 'src/embeds/image/image-dato';

export default function Drawer({ heading, content, contentNode, callouts, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  customData = JSON.parse(customData);

  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        {!!heading && <h2>{heading}</h2>}
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        {callouts.map(({ id, preHeading, heading, contentNode, media, ctas }) => (
          <ArticleTeaser
            key={id}
            image={!!media && <FluidImg data={media} maxWidth={null} style={{ marginTop: '-2rem' }} />}
            verticalAlign="center"
          >
            {!!preHeading && <h5 className="highlight">{preHeading}</h5>}
            {!!heading && <h3>{heading}</h3>}
            {!!contentNode && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
            {ctas.map((cta, i) => (
              <>
                <Link to={cta.url} aria-label={cta.a11yText} key={i}>
                  {cta.text}
                </Link>
                <br />
              </>
            ))}
          </ArticleTeaser>
        ))}
      </Section>
    </MDXProvider>
  );
}
